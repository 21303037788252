import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import { store } from './store';
import MeasureVitals from './pages/measure-vitals';
import Results from './pages/results';
// import Welcome from './pages/welcome-page';
import Disclaimer from './pages/disclaimer';
// import InformedConsent from './pages/informed-consent';
import Instructions from './pages/instructions';
import LicenseError from './pages/license-error';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/measure-vitals" element={<MeasureVitals />} />
        <Route path="/results" element={<Results />} />
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/" element={<Disclaimer />} />
        {/* <Route path="/informed-consent" element={<InformedConsent />} /> */}
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/license-error" element={<LicenseError />} />
        {/* <Route path="*" element={<Welcome />} /> */}
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js', {
        scope: '/',
      });
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch(e) {
      console.log('ServiceWorker registration failed: ', e);
    }
  });
}
