export const CircleExclamationSolid = () => (
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ 'enableBackground': 'new 0 0 24 24' }}>
    <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,17c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,17,12,17z M13,12c0,0.5-0.5,1-1,1s-1-0.5-1-1V8c0-0.5,0.5-1,1-1s1,0.5,1,1V12z" />
  </svg>
);

export const BloodPressure = ({ className, style }) => (
  <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M462.3,62.6L462.3,62.6C407.5,15.9,326,24.3,275.7,76.2L256,96.5l-19.7-20.3C186.1,24.3,104.5,15.9,49.7,62.6c-62.8,53.6-66.1,149.8-9.9,207.9l193.5,199.8c12.5,12.9,32.8,12.9,45.3,0l27.2-28.1C253.4,427.4,215,379.1,215,322c0-69,56-125,125-125c55.8,0,103,36.5,119.1,87l13-13.4C528.4,212.4,525.1,116.2,462.3,62.6z" />
    <g>
      <g>
        <path fill="currentColor" d="M340,242c21.4,0,41.5,8.3,56.6,23.4c31.2,31.2,31.2,81.9,0,113.1C381.5,393.7,361.4,402,340,402s-41.5-8.3-56.6-23.4c-31.2-31.2-31.2-81.9,0-113.1C298.5,250.3,318.6,242,340,242 M340,222c-25.6,0-51.2,9.8-70.7,29.3c-39.1,39.1-39.1,102.4,0,141.4c19.5,19.5,45.1,29.3,70.7,29.3s51.2-9.8,70.7-29.3c39.1-39.1,39.1-102.4,0-141.4C391.2,231.7,365.6,222,340,222L340,222z" />
      </g>
      <path fill="currentColor" d="M377.9,277.2L323,312.9c-2.7,1.7-3.1,5.5-0.8,7.7l19.1,19.1c2.2,2.2,6,1.9,7.7-0.8l35.7-54.8C387.7,279.6,382.4,274.2,377.9,277.2z" />
    </g>
  </svg>
);

export const HeartBeat = ({ className, style }) => (
  <svg aria-hidden="true" style={style} className={className} focusable="false" data-prefix="fas" data-icon="heartbeat" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M320.2 243.8l-49.7 99.4c-6 12.1-23.4 11.7-28.9-.6l-56.9-126.3-30 71.7H60.6l182.5 186.5c7.1 7.3 18.6 7.3 25.7 0L451.4 288H342.3l-22.1-44.2zM473.7 73.9l-2.4-2.5c-51.5-52.6-135.8-52.6-187.4 0L256 100l-27.9-28.5c-51.5-52.7-135.9-52.7-187.4 0l-2.4 2.4C-10.4 123.7-12.5 203 31 256h102.4l35.9-86.2c5.4-12.9 23.6-13.2 29.4-.4l58.2 129.3 49-97.9c5.9-11.8 22.7-11.8 28.6 0l27.6 55.2H481c43.5-53 41.4-132.3-7.3-182.1z" /></svg>
);

export const Lungs = ({ className, style }) => (
  <svg
    className={className}
    style={style}
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="lungs"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
  >
    <path fill="currentColor" d="M636.11 390.15C614.44 308.85 580.07 231 534.1 159.13 511.98 124.56 498.03 96 454.05 96 415.36 96 384 125.42 384 161.71v60.11l-32.88-21.92a15.996 15.996 0 0 1-7.12-13.31V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v170.59c0 5.35-2.67 10.34-7.12 13.31L256 221.82v-60.11C256 125.42 224.64 96 185.95 96c-43.98 0-57.93 28.56-80.05 63.13C59.93 231 25.56 308.85 3.89 390.15 1.3 399.84 0 409.79 0 419.78c0 61.23 62.48 105.44 125.24 88.62l59.5-15.95c42.18-11.3 71.26-47.47 71.26-88.62v-87.49l-85.84 57.23a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09L320 235.23l167.59 111.72a7.994 7.994 0 0 1 2.22 11.09l-8.88 13.31a7.994 7.994 0 0 1-11.09 2.22L384 316.34v87.49c0 41.15 29.08 77.31 71.26 88.62l59.5 15.95C577.52 525.22 640 481.01 640 419.78c0-9.99-1.3-19.94-3.89-29.63z" />
  </svg>
);

export const Spo2 = ({ className, style }) => (
  <svg className={className} style={style} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 512">
    <path fill="currentColor" d="M130.8,384.3c0-69.1,55.9-125,125-125c65,0,118.4,49.6,124.4,113.1c4.7-16.6,7.2-34.2,7.2-52.4c0-77.4-27-99-172.3-309.7c-9.5-13.8-29.9-13.8-39.5,0C30.4,221,3.5,242.6,3.5,320c0,106,86,192,192,192c13.9,0,27.4-1.5,40.5-4.3C176.3,498.2,130.8,446.6,130.8,384.3z" />
    <g>
      <path fill="currentColor" d="M340.9,481.7c0,0,9.7-5.6,19.3-12.5c10.9-7.6,20.1-13.7,20.1-22.3c0-4.9-3-9.3-10.4-9.3c-7.6,0-11.1,5.7-11.1,11.8h-18.1c0-15.2,7.5-26.3,28.9-26.3c23,0,29.3,11.1,29.3,22.3c0,14.3-12.2,22.9-23.2,31.2c-1.8,1.3-4,2.9-6.4,4.5l29.2-0.9v14.9h-57.6V481.7z" />
    </g>
    <path fill="currentColor" d="M255.8,299.4c-46.9,0-84.9,38-84.9,84.9c0,46.9,38,84.9,84.9,84.9s84.9-38,84.9-84.9C340.7,337.4,302.7,299.4,255.8,299.4zM255.8,446.5c-34.4,0-62.2-27.9-62.2-62.2c0-34.4,27.9-62.2,62.2-62.2s62.2,27.9,62.2,62.2C318,418.7,290.2,446.5,255.8,446.5z" />
  </svg>
);
