import React from 'react';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { CircleExclamationSolid } from '../lib/icons';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
    '& path': {
      fill: colors.errorRed,
    },
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: desktopMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    textAlign: 'center',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

class LicenseError extends React.Component {
  constructor(props) {
    super(props);

    this.backButtonHandler = () => {
      window.history.go(1);
    };
    window.addEventListener('popstate', this.backButtonHandler, true);
  }


  render() {
    return (
      <div sytle={styles.page}>
        <div style={styles.pageContent}>
          <div style={styles.exclamationWrapper}>
            <CircleExclamationSolid />
          </div>
          <p><strong>license Error</strong></p>
          <p>There is not an active licence key to use informed Vital Core.</p>
          <p>Please <strong>CLOSE</strong> this browser window.</p>
        </div>
      </div>
    );
  }
}

export default LicenseError;
